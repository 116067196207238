body {
  padding-top: 5rem;
}
.starter-template {
  padding: 3rem 1.5rem;
  text-align: center;
}

.logo {
  position: absolute;
  margin-top: -65px;
  z-index:2;
  
  }

  .top-nav {
    height: 150px;
    margin-top: -88px;
    border-bottom: 4px solid black;
  }

  .menu-list {
    margin-left: 200px;
  }

  #hero {
    background: red; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .odd {
    background: #2c2c2c;
    padding: 60px 30px;

  }

  .even {
    background: #000000;
    padding: 60px 30px;

  }
  #promo1 {
    background: #fdde38;
  }

  #home-space {
    background: #dddddd; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 80px 0;
  }

  #footer {
    background: #303030;
    color:white;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 0.8em;
  }

  .footer1 a {
    padding: 10px;
    color: white;
    font-size: 0.8em;
  }

  #footer-links {
    background : #111111;
    text-align: center;
    padding: 40px 15px;
  }

  #call {
    background: #000000;
    text-align: center;
    color: white;
    padding: 60px 0;
  }

  #newsletter {
    background: #303030 url(background-images/signup-bg.png) no-repeat left center;
    background-size: auto;
    background-size: 400px 420px;
    padding: 60px 0 100px 0;
    text-align: center;
    color: white;
  }

  .carousel-item {
    height: 100vh;
    min-height: 350px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
  }

  .odd {
    text-align: center
  }

  .even {
    text-align: center
  }

  .odd h6 {
   color: #999999
  }

  .even h6 {
    color: #999999
   }

   .odd p {
    color: #fff
   }

   .even p {
    color: #fff
   }

   .companies h3 {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.1em;
    color: #222;
    background: url(background-images/about_companies_icon@2x.png) no-repeat bottom center;
        background-size: auto;
    background-size: 200px 50px;
    padding-bottom: 60px;
}

.companies {
  text-align: center;
  padding: 60px 0 40px 0;
}

.recipes {
  padding-top: 30px;
}

.recipes-header h3 {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.1em;
  color: #222;
  background: url(background-images/about_companies_icon@2x.png) no-repeat bottom center;
      background-size: auto;
  background-size: 200px 50px;
  padding-bottom: 60px;
}

.gal {
	
	
	-webkit-column-count: 6; /* Chrome, Safari, Opera */
    -moz-column-count: 6; /* Firefox */
    column-count: 6;
	  
	
	}	
	.gal img{ width: 100%; padding: 7px 0;}
@media (max-width: 500px) {
		
		.gal {
	
	
	-webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1; /* Firefox */
    column-count: 1;
	  
	
	}
		
  }
  
.fa-stack {
    margin-top: -12px;
  }

.navbar-dark .navbar-nav .nav-link {
    color: #0f0f0f;
  }

.navbar-dark .navbar-nav .active > .nav-link {
color: black;
  }

.bg-white {
    background-color: #fff;
}

.icon-background {
  color: #d9d9d9;
}

.form-length {
  width: 90% !important;
}

#page-title {
  background: #fedd38;
}

#page-title .column {
background: #fedd38 url(background-images/bacon-2x.jpg) no-repeat left center;
background-position: top right;
background-repeat: no-repeat;
background-size: contain;
padding-top: 120px;
padding-bottom: 40px;
}

#page-title .about-column {
  background: #fedd38 url(background-images/banner-2x.jpg) no-repeat left center;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 120px;
  padding-bottom: 40px;
  }

#content {
  background: white;
  padding: 60px 0 60px 0;
}

#content p .big {
font-weight: 100;
font-size: 1.8em;
color: #000;
}

#premium {

  background: #303030 url(background-images/shutterstock-133425698.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 400px;

}

#premium .main {
  text-align: center;
  color: white;
  font-size: 2em;
}

#premium .sub {
  text-align: center;
  vertical-align: middle;
  color: white;
}

#premium .content-body {
  padding-top: 120px;
}

.drop-quote {
  margin: 1em;
  font-size: 2em;
  font-weight: 300;
  color: #999;
  border-top: solid 3px #fedd38;
  border-bottom: solid 3px #fedd38;
  padding: 20px 0;
  width: 100%;
}

.first {
  background:#f0f0f0;
  padding: 70px 80px;
  text-align: center;
}

.last {
  background: #e4e4e4;
  padding: 70px 80px;
  text-align: center;
}

.first img .co-logo {
  width: 100%;
  max-width: 350px;
}

.amp-ceo {
  background-color: #fedd38;
}

.colcom-ceo {
 background: #fffa80;
}

.amp-gm {
  background: #ff000a;
}

.people-column {
  margin: 30px 0 30px 0;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #aaa !important;
  border-bottom: 3px solid #aaa;
}

.navbar-dark .navbar-nav .nav-link:focus {
  color: black !important;
  font-weight: bold;
  border-bottom: 3px solid #fedd38;
}

.navbar-dark .navbar-nav .nav-link:active {
  color: #aaa !important;
}

.people h3 {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.1em;
  color: #222;
  background: url(background-images/people_icon@2x.png) no-repeat bottom center;
      background-size: auto;
  background-size: 200px 50px;
  padding-bottom: 60px;
}

.people {
  text-align: center;
  padding: 60px 0 40px 0;
}

div.recipe-list > div > a.photo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
}

div.recipe-list > div {
  padding: 0;
  position: relative;
  display: block;
  width: 100%;
  height: 300px;
}

div.recipe-list > div > a {
  display: block;
  height: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border: solid 2px #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.recipe-container {
  padding-top: 30px;
  padding-bottom: 50px;
  background: #f0f0f0;
}

div.recipe-list > div > a span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px 20px 20px 20px;
  font-size: 1.2em;
  color: #fff;
  transition: all .4s ease;
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
}

div.recipe-list > div > a:hover span {
  padding-bottom: 30px;
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.95) 100%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#33000000',GradientType=0 );
}





div.home-recipe-list > div > a.photo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
}

div.home-recipe-list > div {
  padding: 0;
  position: relative;
  display: block;
  width: 100%;
  height: 300px;
}

div.home-recipe-list > div > a {
  display: block;
  height: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border: solid 2px #fff;
  position: absolute;
  top: 0;
  left: 0;
}

div.home-recipe-list > div > a span {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px 20px 20px 20px;
  font-size: 0.9em;
  color: #fff;
  transition: all .4s ease;
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
}

div.home-recipe-list > div > a:hover span {
  padding-bottom: 30px;
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.95) 100%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.95) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#33000000',GradientType=0 );
}

.contact-form {
  padding-top: 30px;
  padding-bottom: 50px;
}

.carousel-caption > div > div {
  max-width: 400px;
  background-color: rgba(0, 0, 0, .8);
  padding: 20px;
  margin: 0 auto;
  color: #fff;
}

.carousel-caption {
  top: 20%;
}

